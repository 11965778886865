import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-oceanside-california.png'
import image0 from "../../images/cities/scale-model-of-public-art-\"coast-rail-trail-benches\"-in-oceanside-california.png"
import image1 from "../../images/cities/scale-model-of-public-art-\"bird-of-paradise\"-in-oceanside-california.png"
import image2 from "../../images/cities/scale-model-of-the-grid-in-oceanside-california.png"
import image3 from "../../images/cities/scale-model-of-california-surf-museum-in-oceanside-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Oceanside'
            state='California'
            image={image}
            lat='33.1958696'
            lon='-117.37948340000003'
            attractions={ [{"name": "Public Art \"Coast Rail Trail Benches\"", "vicinity": "Coastal Rail Trail, Carlsbad", "types": ["point_of_interest", "establishment"], "lat": 33.150048, "lng": -117.34160580000002}, {"name": "Public Art \"Bird of Paradise\"", "vicinity": "2031740600, Carlsbad", "types": ["point_of_interest", "establishment"], "lat": 33.1584175, "lng": -117.3509431}, {"name": "The GRID", "vicinity": "206 Artist Alley Suite A, Oceanside", "types": ["point_of_interest", "establishment"], "lat": 33.1963361, "lng": -117.37942270000002}, {"name": "California Surf Museum", "vicinity": "312 Pier View Way, Oceanside", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.1963116, "lng": -117.38152350000001}] }
            attractionImages={ {"Public Art \"Coast Rail Trail Benches\"":image0,"Public Art \"Bird of Paradise\"":image1,"The GRID":image2,"California Surf Museum":image3,} }
       />);
  }
}